import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import emailVerifiedImage from '../assets/emailverified.png'; // Ensure this path is correct
import './signup-modal.css';

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const verifyEmail = async (token) => {
      try {
        // Make API request to verify the email
        const response = await axios.post('/api/verify-email', { token });
        setMessage('Email Verified Successfully!');
        setIsError(false);

        // Stay on the page for 2 seconds before navigating
        setTimeout(() => {
          navigate('/signin');
        }, 3000); // 3 seconds delay
      } catch (error) {
        setMessage('Invalid or expired token');
        setIsError(true);

        // Do not redirect, allow the user to see the error message
      }
    };

    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      verifyEmail(token);
    }
  }, [location.search, navigate]);

  const handleClose = () => {
    setShowModal(false);
    navigate('/signin'); // Allow manual redirection by the user if they wish to sign in
  };

  return (
    <div className="signup-container-custom">
      {showModal && (
        <div className="signup-modal">
          <div className="signup-modal-content">
            <span className="signup-close-button" onClick={handleClose}>&times;</span>
            <img src={emailVerifiedImage} alt="Success" className="signup-success-image" />
            <h2 className="signup-modal-title">{message}</h2>
            {isError ? (
              <p className="signup-modal-text">
                The token is invalid or expired. Please try verifying your email again or contact our support team at <a href="mailto:support@voucherplatform.com">support@voucherplatform.com</a>.
              </p>
            ) : (
              <p className="signup-modal-text">
                Your email address has been successfully verified. Thank you for confirming your email! You can now enjoy full access to all features on our platform, including purchasing and managing your gift cards and vouchers. If you have any questions or need assistance, feel free to contact our support team at <a href="mailto:support@voucherplatform.com">support@voucherplatform.com</a>. Happy gifting!
              </p>
            )}
            {!isError && (
              <div>
                <button className="signup-button-custom" onClick={() => navigate('/dashboard')}>Go to Dashboard</button>
                <button className="signup-button-custom" onClick={() => navigate('/kyc')}>Proceed to KYC</button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VerifyEmail;
