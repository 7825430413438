import React, { useState, useEffect } from 'react';
import './ConfirmOrderModal.css';
import axios from 'axios';
import axiosInstance from '../axiosSetup'; // Adjust the path as needed


const ConfirmOrderModal = ({ show, onClose, giftCard, amountInDollars, priceInNaira, recipientDetails, balance, currencySymbol, user, onConfirmOrder }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    if (user.id) {
      // Fetch user details
      axios.get('/api/user', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
        .then(response => {
          setUserData(response.data);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
        });
    }
  }, [user.id]);

  if (!show) {
    return null;
  }

  const handlePayNow = async () => {
    try {
      if (!giftCard || !giftCard.product_id) {
        throw new Error('Gift card data is missing or invalid.');
      }

      if (!amountInDollars) {
        throw new Error('Amount in dollars is missing.');
      }

      const orderData = {
        userId: user.id,
        product_id: giftCard.product_id,
        amount: amountInDollars,
        priceInNaira,
        recipientDetails: {
          firstName: recipientDetails.firstName || userData.first_name,
          lastName: recipientDetails.lastName || userData.last_name,
          email: recipientDetails.email || userData.email,
          message: recipientDetails.message || ''
        }
      };

      onConfirmOrder(orderData);
    } catch (error) {
      console.error('Error processing order:', error);
      alert(`Error processing order: ${error.message}`);
    }
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-NG', {
      minimumFractionDigits: 2
    }).format(value) + ' NGN';
  };

  return (
    <div className="confirm-order-modal-overlay">
      <div className="confirm-order-modal">
        <div className="confirm-order-modal-header">
          <button className="confirm-order-close-button" onClick={onClose}>×</button>
          <h2 className="confirm-order-modal-title">Confirm Order</h2>
        </div>
        <div className="confirm-order-modal-body">
          <div className="confirm-order-giftcard-image">
            {giftCard.logo_urls && giftCard.logo_urls.length > 0 ? (
              <img src={`/image-proxy?url=${btoa(giftCard.logo_urls[0])}`} alt={giftCard.product_name} />
            ) : (
              <p>Image not available</p>
            )}
          </div>
          <div className="confirm-order-giftcard-details">
            <p>Gift card price: <span>{currencySymbol}{amountInDollars}</span></p>
            <p>Amount in Naira: <span>{formatCurrency(parseFloat(priceInNaira))}</span></p>
            <p>Fee: <span>{formatCurrency(0.00)}</span></p>
            <p>Pay with: <span>NGN Balance</span></p>
          </div>
          <button className="confirm-order-pay-now-button" onClick={handlePayNow}>
            <span className="confirm-order-button-title">Pay {formatCurrency(parseFloat(priceInNaira) + 500)}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmOrderModal;
