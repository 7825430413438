import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/LogoPurple.png';
import homeIcon from '../assets/homeIconActive.png';
import giftcardIcon from '../assets/giftcardIconInactive.png';
import transactionIcon from '../assets/transactionIconInactive.png';
import balanceBackground from '../assets/balanceBackground.png';
import AddMoneyModal from './AddMoneyModal';
import './Dashboard.css';
import axiosInstance from '../axiosSetup'; // Adjust the path as needed

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(null);
  const [giftcards, setGiftcards] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showKycMessage, setShowKycMessage] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get('/api/user', { withCredentials: true });
        setUser(response.data);
        setBalance(response.data.availablebalance);
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/signin');
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    if (!user) return;

    const fetchGiftcards = async () => {
      try {
        const response = await axios.get('/api/giftcards', { params: { size: 18, page: 1 } });
        if (response.data && Array.isArray(response.data.products)) {
          setGiftcards(response.data.products);
        } else {
          setGiftcards([]);
        }
      } catch (error) {
        console.error('Error fetching giftcards:', error);
      }
    };

    const fetchTransactions = async () => {
      try {
        const response = await axios.get('/api/transactions');
        if (Array.isArray(response.data)) {
          setTransactions(response.data);
        } else {
          setTransactions([]);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchGiftcards();
    fetchTransactions();
  }, [user]);

  if (!user) {
    return <p>Loading...</p>;
  }

  const initials = `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`.toUpperCase();

  const openModal = () => {
    if (!user.kycstatus) {
      setShowKycMessage(true);
      setTimeout(() => {
        setShowKycMessage(false);
      }, 2000);
    } else {
      setShowModal(true);
    }
  };

  const closeModal = () => setShowModal(false);

  const handleNavigate = (path) => {
    navigate(path, { state: { user } });
  };
// Add the handleCardClick function
const handleCardClick = (giftCard) => {
  if (!giftCard.product_id) {
    console.error('Product ID is missing:', giftCard);
    alert('Product ID is missing for this gift card. Please try again later.');
    return;
  }
  console.log(`Navigating to gift card purchase page with product ID: ${giftCard.product_id}`);
  navigate(`/giftcard/${giftCard.product_id}`, { state: { user, giftCard } });
};


  

  const formatAmount = (amount) => {
    return parseFloat(amount).toLocaleString('en-NG', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <img src={logo} alt="Logo" className="dashboard-logo" />
        <nav className="dashboard-nav">
          <div 
            onClick={() => handleNavigate('/dashboard')}
            className={location.pathname === '/dashboard' ? 'active' : ''}
          >
            <img src={homeIcon} alt="Home" /> Home
          </div>
          <div onClick={() => handleNavigate('/MainGiftCard')}>
            <img src={giftcardIcon} alt="Buy Giftcards" /> Buy Giftcards
          </div>
          <div onClick={() => handleNavigate('/transactions')}>
            <img src={transactionIcon} alt="Transaction History" /> Transaction History
          </div>
        </nav>
        <div className="dashboard-profile">
          <div className="profile-icon" onClick={() => handleNavigate('/profile')}>{initials}</div>
        </div>
      </header>

      <div className="dashboard-main-content">
        <div className="dashboard-wrapper">
          <div className="dashboard-left">
            <div className="dashboard-salutation-wrapper">
              <div className="dashboard-salutation">
                <h1>Welcome, {user.first_name} 👋</h1>
              </div>
              <div className="kyc-section">
                {!user.kycstatus && (
                  <>
                    <button className="complete-kyc-button" onClick={() => handleNavigate('/complete-kyc')}>Complete KYC</button>
                    {showKycMessage && (
                      <p className="kyc-warning">Please complete KYC to add money.</p>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="balance-view-card" style={{ backgroundImage: `url(${balanceBackground})` }}>
              <p>Your balance:</p>
              <h2>₦{formatAmount(balance)}</h2>
              <button 
                className="add-money-button" 
                onClick={openModal}
              >
                + Add Money
              </button>
            </div>

            <div className="popular-giftcards">
  <h3>Popular Giftcards</h3>
  <div onClick={() => handleNavigate('/MainGiftCard')} className="more-giftcards-link">Check out more gift cards</div>
  <div className="giftcards-list">
    {giftcards.length > 0 ? (
      giftcards.map((giftcard, index) => (
        <div key={index} className="giftcard-item" onClick={() => handleCardClick(giftcard)}>
          {giftcard.logos.map((logoUrl, logoIndex) => (
            <img key={logoIndex} src={`/image-proxy?url=${btoa(logoUrl)}`} alt={giftcard.name} />
          ))}
          <p>{giftcard.name}</p>
        </div>
      ))
    ) : (
      <p>No gift cards available.</p>
    )}
  </div>
</div>

          </div>

          <div className="dashboard-right">
            <div className="recent-transactions-container">
              <h3>Recent Transactions</h3>
              <div onClick={() => handleNavigate('/transactions')} className="view-all-transactions-link">View all</div>
            </div>
            <div className="recent-transactions">
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Voucher</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.length > 0 ? (
                    transactions.slice(0, 6).map((transaction, index) => (
                      <tr key={index}>
                        <td>{formatDate(transaction.datetime)}</td>
                        <td>{transaction.giftcard}</td>
                        <td>₦{formatAmount(transaction.amount_naira)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3">No transactions available.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <footer className="dashboard-footer">
        <p>© Copyright 2024, All Rights Reserved</p>
      </footer>

      {showModal && <AddMoneyModal closeModal={closeModal} kycCompleted={user.kycstatus} />}
    </div>
  );
};

export default Dashboard;
