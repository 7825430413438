import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';
import LogoFooter from '../assets/LogoFooter.png'; // Adjust the path accordingly

const Footer = () => {
  return (
    <footer className="footer py-10 bg-gray-100 text-black">
     <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="footer-top flex justify-between items-center border-b border-gray-300 opacity-20">
      <img src={LogoFooter} alt="Sparkpay Logo" className="footer-logo mb-4" />
        <div className="footer-links flex space-x-8">
            <a href="https://yourwebsite.com/faqs" className="footer-link">FAQs</a>
            <a href="https://yourwebsite.com/privacy" className="footer-link">Privacy</a>
            <a href="https://yourwebsite.com/terms" className="footer-link">Terms</a>
            <a href="https://yourwebsite.com/support" className="footer-link">Support</a>
          </div>
          <div className="footer-social flex space-x-4">
            <a href="https://twitter.com" className="social-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="https://facebook.com" className="social-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://instagram.com" className="social-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://github.com" className="social-link" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} />
            </a>
          </div>
        </div>
        <div className="footer-bottom flex justify-between items-center mt-4">
          <p>&copy; 2024 Sparkpay. All rights reserved.</p>
          <p>1 Ajidagba Crescent, Tokunbo Macaulay Street, Magodo Phase 2, Lagos, Nigeria.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
