import React from 'react';
import WideRangeImage from '../assets/WideRangeImage.png';
import EasyPaymentsImage from '../assets/EasyPaymentsImage.png';
import DigitalDeliveryImage from '../assets/DigitalDeliveryImage.png';
import CustomerSupportImage from '../assets/CustomerSupportImage.png';



const WhyChooseUs = () => {
    return (
      <section className="why-choose-us-section">
        <div className="why-choose-us-container">
          <div className="why-choose-us-title-container">
            <span className="line line-left"></span>
            <h2 className="why-choose-us-title">Why Choose Us?</h2>
     

          </div>
          <div className="why-choose-us-grid">
            <div className="image-container wide-range">
              <img src={WideRangeImage} alt="Wide Range of Choices" className="card-image" />
            </div>
            <div className="image-container customer-support">
              <img src={CustomerSupportImage} alt="Excellent Customer Support" className="card-image" />
            </div>
            <div className="image-container easy-payments">
              <img src={EasyPaymentsImage} alt="Easy and Secure Payments" className="card-image" />
            </div>
            <div className="image-container digital-delivery">
              <img src={DigitalDeliveryImage} alt="Instant Digital Delivery" className="card-image" />
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default WhyChooseUs;