import React, { useState, useEffect } from 'react';
import axios from 'axios';
import bankTransferIcon from '../assets/bankTransferIcon.png'; // Adjust the path as needed
import cardIcon from '../assets/cardIcon.png'; // Adjust the path as needed
import NairaInput from './NairaInput'; // Import the custom input component
import './AddMoneyModal.css';

const AddMoneyModal = ({ closeModal }) => {
  const [step, setStep] = useState(1);
  const [accountDetails, setAccountDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAccountDetails = async () => {
      try {
        const response = await axios.get('/api/account-details');
        setAccountDetails(response.data);
      } catch (error) {
        console.error('Error fetching account details:', error);
      }
    };

    fetchAccountDetails();
  }, []);

  const generateAccountNumber = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/api/generate-account');
      setAccountDetails(response.data);
    } catch (error) {
      console.error('Error generating account number:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    const details = document.querySelector('.account-details').innerText;
    const textArea = document.createElement('textarea');
    textArea.value = details;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
  };

  return (
    <div className="AddMoneyModal-modal">
      <div className="AddMoneyModal-modal-content">
        {step === 1 && (
          <>
            <div className="AddMoneyModal-modal-header">
              <h3>Add Money</h3>
              <button className="AddMoneyModal-close-button" onClick={closeModal}>&times;</button>
            </div>
            <div className="AddMoneyModal-modal-body">
              <div className="AddMoneyModal-add-money-options">
                <div className="AddMoneyModal-add-money-option" onClick={() => setStep(2)}>
                  <img src={bankTransferIcon} alt="Bank Transfer" />
                  <span>Fund with bank transfer</span>
                </div>
                <div className="AddMoneyModal-add-money-option" onClick={() => setStep(3)}>
                  <img src={cardIcon} alt="Card" />
                  <span>Fund with Card</span>
                </div>
              </div>
            </div>
          </>
        )}
        {step === 2 && accountDetails && (
          <>
            <div className="AddMoneyModal-modal-header">
              <button className="AddMoneyModal-back-button" onClick={() => setStep(1)}>&larr;</button>
              <h3>Bank Account Details</h3>
              <button className="AddMoneyModal-close-button" onClick={closeModal}>&times;</button>
            </div>
            <div className="AddMoneyModal-modal-body">
              <div className="AddMoneyModal-account-details">
                <p><span>Bank Name:</span> <span>{accountDetails.bank_name}</span></p>
                <p><span>Account Number:</span> <span>{accountDetails.account_number}</span></p>
                <p><span>Account Name:</span> <span>{accountDetails.account_name}</span></p>
                <button className="AddMoneyModal-copy-button" onClick={handleCopy}>Copy Account Details</button>
              </div>
            </div>
          </>
        )}
        {step === 2 && !accountDetails && (
          <>
            <div className="AddMoneyModal-modal-header">
              <button className="AddMoneyModal-back-button" onClick={() => setStep(1)}>&larr;</button>
              <h3>Bank Account Details</h3>
              <button className="AddMoneyModal-close-button" onClick={closeModal}>&times;</button>
            </div>
            <div className="AddMoneyModal-modal-body">
              <div className="AddMoneyModal-generate-container">
                <p className="AddMoneyModal-generate-message">No account details found.</p>
                <button
                  className="AddMoneyModal-generate-button"
                  onClick={generateAccountNumber}
                  disabled={loading}
                >
                  {loading ? 'Generating...' : 'Generate Account Number'}
                </button>
              </div>
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <div className="AddMoneyModal-modal-header">
              <button className="AddMoneyModal-back-button" onClick={() => setStep(1)}>&larr;</button>
              <h3>Fund with Card</h3>
              <button className="AddMoneyModal-close-button" onClick={closeModal}>&times;</button>
            </div>
            <div className="AddMoneyModal-modal-body">
              <div className="AddMoneyModal-fund-card">
                <label>Amount</label>
                <NairaInput value="" onChange={() => {}} />
                <button className="AddMoneyModal-continue-button">Continue</button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddMoneyModal;
