import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from './api'; // Assuming you have an api.js file configured for axios
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/LogoPurple.png';
import homeIcon from '../assets/homeIconInactive.png';
import giftcardIcon from '../assets/giftcardIconActive.png';
import transactionIcon from '../assets/transactionIconInactive.png';
import Pagination from './Pagination';
import './MainGiftCard.css';
import './loading.css';

const categories = [
  { id: 'All', name: 'All Categories' },
  { id: 4, name: 'Food and Entertainment' },
  { id: 5, name: 'Fashion and Retail Shopping' },
  { id: 1, name: 'Finance & Prepaid Cards' },
  { id: 2, name: 'Computer & Software Solutions' },
  { id: 3, name: 'Gaming and eSports' },
  { id: 6, name: 'Cryptocurrencies & Digital Assets' },
];

const MainGiftCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user;
  const [giftcards, setGiftcards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [cachedPages, setCachedPages] = useState({});

  useEffect(() => {
    if (!user) {
      console.log('User not found, redirecting to sign in');
      navigate('/signin');
      return;
    }

    console.log('User found:', user);

    const prefetchData = async () => {
      try {
        await api.get('/api/giftcards/prefetch');
      } catch (error) {
        console.error('Error prefetching data:', error);
      }
    };

    prefetchData();
  }, [user, navigate]);

  useEffect(() => {
    const fetchGiftcards = async () => {
      if (cachedPages[`${selectedCategory}-${page}`]) {
        setGiftcards(cachedPages[`${selectedCategory}-${page}`].giftCards);
        setHasMore(cachedPages[`${selectedCategory}-${page}`].hasMore);
        setLoading(false);
        console.log('Data fetched from cache');
        return;
      }

      setLoading(true);
      console.log('Loader set to true');
      const startTime = performance.now();
      try {
        const url = selectedCategory === 'All' ? '/api/giftcards/category' : '/api/giftcards/category';
        const params = selectedCategory === 'All' ? { category: 'All', page, size: 32 } : { category: selectedCategory, page, size: 32 };
        const response = await api.get(url, { params });

        console.log('Requesting gift cards with params:', params);
        console.log('Response data:', response.data);

        if (response.data && Array.isArray(response.data.giftCards)) {
          setGiftcards(response.data.giftCards);
          setHasMore(response.data.hasMore || false);
          setTotalPages(Math.ceil(response.data.totalItems / 30));
          setCachedPages(prev => ({
            ...prev,
            [`${selectedCategory}-${page}`]: {
              giftCards: response.data.giftCards,
              hasMore: response.data.hasMore || false
            }
          }));
          console.log(`Total pages set to: ${Math.ceil(response.data.totalItems / 30)}, Has more: ${response.data.hasMore}`);
        } else {
          setGiftcards([]);
        }
      } catch (error) {
        console.error('Error fetching giftcards:', error);
      } finally {
        const endTime = performance.now();
        const loadTime = endTime - startTime;
        const minimumDelay = 1000; // Minimum delay of 1 second
        const remainingTime = Math.max(minimumDelay - loadTime, 0); // Calculate remaining time to show loader for at least 1 second
        console.log(`Fetch time: ${endTime - startTime} ms, Loader will be hidden in ${remainingTime} ms`);
        setTimeout(() => {
          setLoading(false);
          console.log('Loader set to false');
        }, remainingTime);
        console.log(`Current page: ${page}, Total pages: ${totalPages}, Has more: ${hasMore}`);
      }
    };

    fetchGiftcards();
  }, [selectedCategory, page, cachedPages]);

  const initials = `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`.toUpperCase();
  console.log('User initials:', initials);

  if (!user) {
    return <p>Loading...</p>;
  }

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.trim() === '') {
      setPage(1);
      setSelectedCategory('All');
      setGiftcards([]);
      return;
    }

    setLoading(true);
    console.log('Loader set to true for search');
    try {
      const startTime = performance.now();
      const response = await api.get('/api/giftcards/search', { params: { query: value } });
      if (response.data && Array.isArray(response.data.giftCards)) {
        setGiftcards(response.data.giftCards);
        setHasMore(false);
      } else {
        setGiftcards([]);
      }
      const endTime = performance.now();
      const loadTime = endTime - startTime;
      const minimumDelay = 1000; // Minimum delay of 1 second
      const remainingTime = Math.max(minimumDelay - loadTime, 0); // Calculate remaining time to show loader for at least 1 second
      console.log(`Search fetch time: ${endTime - startTime} ms, Loader will be hidden in ${remainingTime} ms`);
      setTimeout(() => {
        setLoading(false);
        console.log('Loader set to false for search');
      }, remainingTime);
    } catch (error) {
      console.error('Error searching giftcards:', error);
      setGiftcards([]);
      setLoading(false);
    }
  };

  const handleCategoryClick = (categoryId) => {
    if (categoryId === selectedCategory) {
      setPage(1);
      setCachedPages({});
    }
    setSelectedCategory(categoryId);
    setGiftcards([]);
    setLoading(true);
    console.log('Loader set to true for category change');
    console.log(`Selected category: ${categoryId}`);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setLoading(true);
    console.log('Loader set to true for page change');
  };

  const handleCardClick = (giftCard) => {
    console.log(`Navigating to gift card purchase page with product ID: ${giftCard.product_id}`);
    navigate(`/giftcard/${giftCard.product_id}`, { state: { user, countryIsoName: giftCard.country.isoName } });
  };

  const handleScrollRight = () => {
    const categories = document.querySelector('.maingiftcard-categories');
    categories.scrollBy({
      left: 100, // Adjust this value to control the scroll distance
      behavior: 'smooth'
    });
  };

  const filteredGiftcards = giftcards.filter(giftcard =>
    giftcard.product_name && giftcard.product_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleNavigate = (path) => {
    navigate(path, { state: { user } });
  };

  return (
    <div className="maingiftcard-container">
      {loading && (
        <div className="loading-overlay">
          <div className="loader">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <header className="maingiftcard-header">
        <img src={logo} alt="GyftcardNG Logo" className="maingiftcard-logo" />
        <nav className="maingiftcard-nav">
          <div 
            onClick={() => handleNavigate('/dashboard')}
            className={location.pathname === '/dashboard' ? 'active' : ''}
          >
            <img src={homeIcon} alt="Home" /> Home
          </div>
          <div 
            onClick={() => handleNavigate('/MainGiftCard')}
            className={location.pathname === '/MainGiftCard' ? 'active' : ''}
          >
            <img src={giftcardIcon} alt="Buy Giftcards" /> Buy Giftcards
          </div>
          <div onClick={() => handleNavigate('/transactions')}>
            <img src={transactionIcon} alt="Transaction History" /> Transaction History
          </div>
        </nav>
        <div className="maingiftcard-profile">
          <div className="maingiftcard-profile-icon" onClick={() => handleNavigate('/profile')}>{initials}</div> {/* Update this line */}
        </div>
      </header>

      <div className="maingiftcard-main-content">
        <div className="maingiftcard-header-content">
          <h2 className="maingiftcard-title">Buy Gift Cards</h2>
          <div className="maingiftcard-search">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input
              type="text"
              className="search-input"
              value={searchTerm}
              onChange={handleInputChange}
            />
            <span className={`placeholder-text ${searchTerm ? 'hidden' : ''}`}>
              Search
            </span>
          </div>
        </div>
        {!searchTerm && (
          <div className="maingiftcard-categories-wrapper">
            <div className="maingiftcard-categories">
              {categories.map(category => (
                <button
                  key={category.id}
                  className={`category-button ${selectedCategory === category.id ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category.id)}
                >
                  {category.name}
                </button>
              ))}
            </div>
            <div className="category-fade"></div>
            <div className="category-arrow" onClick={handleScrollRight}>➔</div>
          </div>
        )}
        <div className="main-giftcards-list">
          {loading ? null : (
            filteredGiftcards.length > 0 ? (
              filteredGiftcards.map((giftcard, index) => (
                <div key={index} className="main-giftcard-item" onClick={() => handleCardClick(giftcard)}>
                  {giftcard.logo_urls.map((logoUrl, logoIndex) => (
                    <div key={logoIndex} className="giftcard-logo">
                      <img src={`/image-proxy?url=${btoa(logoUrl)}`} alt={giftcard.product_name} />
                      <p>{giftcard.product_name}</p>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <p>No gift cards available.</p>
            )
          )}
        </div>
        {!loading && !searchTerm && (
          <Pagination
            totalPages={totalPages}
            currentPage={page}
            onPageChange={handlePageChange}
          />
        )}
      </div>

      <footer className="maingiftcard-footer">
        <p>© Copyright 2024, All Rights Reserved. Bitbloom Technologies.</p>
      </footer>
    </div>
  );
};

export default MainGiftCard;