import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/logo.png';
import backgroundImage from '../assets/background.png';
import successImage from '../assets/success.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './loading.css';
import './signup-modal.css';

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: ''
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resendTimer, setResendTimer] = useState(0);

useEffect(() => {
  const verifyEmail = async (token) => {
    try {
      const response = await axios.post('/api/verify-email', { token });
      setVerificationMessage('Email Verified Successfully!');
    } catch (error) {
      setVerificationMessage('Invalid or expired token');
    } finally {
      setShowModal(true);
    }
  };

  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  if (token) {
    verifyEmail(token);
  }

  // Start the resend timer by default
  setResendTimer(30);
}, [location.search]);


  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

const handleSubmit = async (e) => {
  e.preventDefault();

  if (!validatePassword(formData.password)) {
    setErrorMessage('Password must be at least 8 characters long and include both letters and numbers or special characters.');
    return;
  }

  try {
    const response = await axios.post('/api/signup', formData);
    console.log('User created:', response.data);
    setShowModal(true);
    setResendTimer(30); // Start the timer when the modal is shown
    setErrorMessage('');
  } catch (error) {
    console.error('There was an error creating the user!', error);
    if (error.response && error.response.data && error.response.data.error) {
      setErrorMessage(error.response.data.error);
    } else {
      setErrorMessage('Error creating user. Please try again.');
    }
    setShowModal(false);
  }
};


const handleResendVerification = async () => {
  if (resendTimer > 0) return;

  try {
    await axios.post('/api/resend-verification', { email: formData.email });
    setResendTimer(30);
  } catch (error) {
    console.error('Error resending verification email:', error);
    setErrorMessage('Error resending verification email. Please try again.');
  }
};


useEffect(() => {
  if (showModal && resendTimer > 0) {
    const timer = setInterval(() => {
      setResendTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }
}, [showModal, resendTimer]);



  const formatPhoneNumber = (value) => {
    const phone = value.replace(/[^\d]/g, ''); // Remove all non-numeric characters
    const phoneNumberLength = phone.length;

    if (phoneNumberLength < 4) return phone;
    if (phoneNumberLength < 8) return `${phone.slice(0, 3)} ${phone.slice(3)}`;
    return `${phone.slice(0, 3)} ${phone.slice(3, 7)} ${phone.slice(7, 11)}`;
  };

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
    setFormData({
      ...formData,
      phoneNumber: formattedPhoneNumber
    });
  };

  return (
    <div className="signup-container-custom">
      <div className="signup-left-custom" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <img src={logo} alt="Sparkpay Logo" className="signup-logo" />
        <p className="logo-description">Gift Smiles, One Voucher at a Time</p>
      </div>
      <div className="signup-right-custom">
        <h1 className="signup-title-custom">Create an Account</h1>
        <form className="signup-form-custom" onSubmit={handleSubmit}>
          <div className="form-group-inline">
            <div className="form-group-custom">
              <label htmlFor="first-name">First Name</label>
              <input type="text" id="first-name" name="firstName" placeholder="Enter first name" onChange={handleChange} required />
            </div>
            <div className="form-group-custom">
              <label htmlFor="last-name">Last Name</label>
              <input type="text" id="last-name" name="lastName" placeholder="Enter last name" onChange={handleChange} required />
            </div>
          </div>
          <div className="form-group-custom">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter email address"
              maxLength="40"
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group-custom">
            <label htmlFor="phone-number">Phone Number</label>
            <input
              type="tel"
              id="phone-number"
              name="phoneNumber"
              placeholder="Enter phone number"
              value={formData.phoneNumber}
              onChange={handlePhoneNumberChange}
              required
            />
          </div>
          <div className="form-group-custom">
            <label htmlFor="password">Password</label>
            <div className="password-container">
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Enter password"
                maxLength="30"
                onChange={handleChange}
                required
              />
              <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
              </span>
            </div>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}

          <button type="submit" className="signup-button-custom">Sign Up</button>
        </form>
        <p className="signup-link-custom">Already have an account? <a href="/signin"><span>Sign In</span></a> instead</p>
      </div>
{showModal && (
  <div className="signup-modal">
    <div className="signup-modal-content">
      <span className="signup-close-button" onClick={() => setShowModal(false)}>&times;</span>
      <img src={successImage} alt="Success" className="success-image" />
      <h2 className="signup-modal-title">
        {verificationMessage ? 'Email Verified Successfully!' : 'Thank you for signing up!'}
      </h2>
      <p className="signup-modal-text">
        {verificationMessage ? '' : `To complete your registration, please check your email and verify your account. We’ve sent a verification link to ${formData.email}. Simply click on the link to activate your account. If you haven’t received the email, please check your spam folder.`}
        {!verificationMessage && (
          <>
            <button 
              className="resend-button-custom"
              onClick={handleResendVerification}
              disabled={resendTimer > 0}
              style={{ opacity: resendTimer > 0 ? 0.5 : 1 }}
            >
              {resendTimer > 0 ? `Resend verification email in ${resendTimer}s` : 'Resend Verification Email'}
            </button>
          </>
        )}
      </p>
      {verificationMessage && (
        <button className="signup-button-custom" onClick={() => navigate('/signin')}>Proceed to Login</button>
      )}
      <p className="signup-modal-footer">Need help? Contact our support team at <a href="mailto:support@voucherplatform.com">support@voucherplatform.com</a>. Thank you for joining our community!</p>
    </div>
  </div>
)}

    </div>
  );
};

export default SignUp;