import React, { useState, useEffect } from 'react';
import logo from '../assets/logo.png'; // Ensure you replace this with the correct path to your logo
import backgroundImage from '../assets/background.png'; // Ensure you replace this with the correct path to your background image
import emailImage from '../assets/email.png'; // Import email success image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'; // Import axios for making HTTP requests
import './forgetpassword-modal.css';
import './forgetpassword.css';

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Define the error message state
  const [successMessage, setSuccessMessage] = useState(''); // Define the success message state
  const [timer, setTimer] = useState(0); // State for timer

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/forgot-password', formData);
      console.log('Password reset email sent:', response.data);
      setModalContent('success');
      setShowModal(true);
      setErrorMessage('');
      setTimer(30); // Start the timer
    } catch (error) {
      console.error('There was an error sending the password reset email!', error);
      setErrorMessage('Unable to send password reset email. Please try again.');
      setSuccessMessage('');
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    if (timer > 0) return; // Prevent resending if timer is active

    try {
      const response = await axios.post('/api/forgot-password', formData);
      console.log('Password reset email resent:', response.data);
      setSuccessMessage('The password reset email has been resent.');
      setErrorMessage('');
      setTimer(30); // Start the timer
    } catch (error) {
      console.error('There was an error resending the password reset email!', error);
      setErrorMessage('Unable to resend password reset email. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="forgot-password-container-custom">
      <div className="forgot-password-left-custom" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <img src={logo} alt="Sparkpay Logo" className="forgot-password-logo" />
        <p className="logo-description">Gift Smiles, One Voucher at a Time</p>
      </div>
      <div className="forgot-password-right-custom">
        <h1 className="forgot-password-title-custom">Forgot Password</h1>
        <form className="forgot-password-form-custom" onSubmit={handleSubmit}>
          <div className="forgot-password-form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter email address"
              className="forgot-password-input"
              onChange={handleChange}
              required
            />
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          <button type="submit" className="forgot-password-button">Send Link</button>
          <p className="back-to-login-link">
            <a href="/signin"><FontAwesomeIcon icon={faArrowLeft} /> Back to Login</a>
          </p>
        </form>
      </div>

      {showModal && (
        <div className="Forgetpassword-modal">
          <div className="Forgetpassword-modal-content">
            <span className="Forgetpassword-close-button" onClick={() => setShowModal(false)}>&times;</span>
            {modalContent === 'success' && (
              <>
                <img src={emailImage} alt="Email Sent" className="success-image" />
                <h2 className="Forgetpassword-modal-title">Check your inbox</h2>
                <p className="Forgetpassword-modal-text">
                  We’ve sent you an email with instructions to reset your password. Please check your inbox and click the link provided to create a new password. If you don’t see the email in your inbox, please check your spam folder or <a href="#" onClick={handleResend} style={{ pointerEvents: timer > 0 ? 'none' : 'auto', opacity: timer > 0 ? 0.5 : 1 }}>resend the password reset email {timer > 0 && `in ${timer} seconds`}</a>.
                </p>
                <p className="Forgetpassword-modal-footer">
                  Need further assistance? Contact our support team at <a href="mailto:support@voucherplatform.com">support@voucherplatform.com</a>. Thank you!
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
