import React from 'react';
import DiningIcon from '../assets/DiningIcon.png';
import ShoppingIcon from '../assets/ShoppingIcon.png';
import EntertainmentIcon from '../assets/EntertainmentIcon.png';
import HealthIcon from '../assets/HealthIcon.png';

const PopularCategories = () => {
  return (
    <section className="popular-categories-section">
      <div className="popular-categories-container">
        <div className="popular-categories-title-container">
          <h2 className="popular-categories-title">Popular Categories</h2>
        
        </div>
        <div className="popular-categories-cards">
        <div className="category-item">
  <div className="category-card">
    <img className="category-icon" src={DiningIcon} alt="Dining & Restaurants" />
  </div>
  <h3 className="category-title">Dining & Restaurants</h3>
</div>


<div className="category-item">
  <div className="category-card">
    <img className="category-icon" src={ShoppingIcon} alt="Shopping" />
  </div>
  <h3 className="category-title">Shopping</h3>
</div>

<div className="category-item">
  <div className="category-card">
    <img className="category-icon" src={EntertainmentIcon} alt="Entertainment & Experiences" />
  </div>
  <h3 className="category-title">Entertainment</h3>
</div>


          <div className="category-item">
            <div className="category-card">
              <img className="category-icon" src={HealthIcon} alt="Health & Wellness" />
            </div>
            <h3 className="category-title">Health & Wellness</h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopularCategories;
