import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'; 
import backgroundImage from '../assets/background.png';
import passwordImage from '../assets/Password.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const [token, setToken] = useState(null); // State to store the token
  const [formData, setFormData] = useState({
    newPassword: '',
    verifyPassword: ''
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  useEffect(() => {
    const tokenFromUrl = query.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
      sessionStorage.setItem('resetToken', tokenFromUrl); // Optionally, store it in session storage
      navigate('/reset-password', { replace: true }); // Navigate to the same page without the token in the URL
    } else {
      const storedToken = sessionStorage.getItem('resetToken');
      if (storedToken) {
        setToken(storedToken);
      }
    }
  }, [navigate, query]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  if (formData.newPassword !== formData.verifyPassword) {
    setErrorMessage('Passwords do not match.');
    return;
  }

  try {
    const response = await axios.post('/api/reset-password', { newPassword: formData.newPassword }, {
      headers: {
        'x-reset-token': token // Ensure this is the encrypted token
      }
    });
    console.log('Password reset successful:', response.data);
    setSuccessMessage('Your password has been successfully reset.');
    setErrorMessage('');
    setShowResetPasswordModal(true);
    sessionStorage.removeItem('resetToken'); // Remove the token from session storage
    setTimeout(() => {
      setShowResetPasswordModal(false);
      navigate('/signin');
    }, 3000);
  } catch (error) {
    console.error('There was an error resetting the password!', error);
    setErrorMessage('Unable to reset password. Please try again.');
    setSuccessMessage('');
  }
};



  return (
    <div className="reset-password-container-custom">
      <div className="reset-password-left-custom" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <img src={logo} alt="Sparkpay Logo" className="reset-password-logo" />
        <p className="logo-description">Gift Smiles, One Voucher at a Time</p>
      </div>
      <div className="reset-password-right-custom">
        <h1 className="reset-password-title-custom">Reset Password</h1>
        <form className="reset-password-form-custom" onSubmit={handleSubmit}>
          <div className="form-group-custom">
            <label htmlFor="newPassword">New Password</label>
            <div className="password-container">
              <input
                type={passwordVisible ? "text" : "password"}
                id="newPassword"
                name="newPassword"
                placeholder="Enter new password"
                onChange={handleChange}
                required
              />
              <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
              </span>
            </div>
          </div>
          <div className="form-group-custom">
            <label htmlFor="verifyPassword">Verify Password</label>
            <div className="password-container">
              <input
                type={passwordVisible ? "text" : "password"}
                id="verifyPassword"
                name="verifyPassword"
                placeholder="Verify new password"
                onChange={handleChange}
                required
              />
              <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
              </span>
            </div>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          <button type="submit" className="reset-password-button-custom">Reset Password</button>
          <p className="back-to-login-link">
            <a href="/signin"><FontAwesomeIcon icon={faArrowLeft} /> Back to Login</a>
          </p>
        </form>
      </div>

      {showResetPasswordModal && (
        <div className="reset-password-modal">
          <div className="reset-password-modal-content">
            <span className="reset-password-close-button" onClick={() => setShowResetPasswordModal(false)}>&times;</span>
            <img src={passwordImage} alt="Success" className="reset-password-success-image" />
            <h2 className="reset-password-modal-title">Password Reset Successful</h2>
            <p className="reset-password-modal-text">Your password has been reset successfully. You can now log in using your new password.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
