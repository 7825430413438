import React, { useState } from 'react';
import './ChangePasswordModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import successImage from '../assets/success.png'; // Import the success image

const ChangePasswordModalUnique = ({ show, onClose }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [passwordChanged, setPasswordChanged] = useState(false);

  if (!show) {
    return null;
  }

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
    return regex.test(password);
  };

  const handlePasswordChange = async () => {
    setError('');
    setSuccess('');

    if (!validatePassword(newPassword)) {
      setError('New password must be at least 8 characters long and contain both letters and numbers.');
      return;
    }

    try {
      const response = await axios.post('/api/change-password', {
        oldPassword,
        newPassword,
      }, { withCredentials: true });

      if (response.data.success) {
        setSuccess('Password changed successfully.');
        setPasswordChanged(true);
        setTimeout(() => {
          onClose();
        }, 1500); // 1.5 seconds delay before closing the modal
      } else {
        setError('Old password is incorrect.');
      }
    } catch (error) {
      setError('Error changing password. Please try again.');
      console.error('Error changing password:', error);
    }
  };

  return (
    <div className="unique-modal-overlay">
      <div className="unique-modal-container">
        <div className="unique-modal-header">
          <h2>Change Password</h2>
          <button className="unique-modal-close-button" onClick={onClose}>×</button>
        </div>
        <div className="unique-modal-body">
          {error && <div className="unique-modal-error">{error}</div>}
          {!passwordChanged && (
            <>
              <div className="unique-modal-input-group">
                <label>Old Password</label>
                <div className="unique-modal-input-wrapper">
                  <input
                    type={showOldPassword ? 'text' : 'password'}
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="Enter old password"
                  />
                  <button
                    type="button"
                    className="unique-modal-visibility-toggle"
                    onClick={() => setShowOldPassword(!showOldPassword)}
                  >
                    <FontAwesomeIcon icon={showOldPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
              </div>
              <div className="unique-modal-input-group">
                <label>New Password</label>
                <div className="unique-modal-input-wrapper">
                  <input
                    type={showNewPassword ? 'text' : 'password'}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter new password"
                  />
                  <button
                    type="button"
                    className="unique-modal-visibility-toggle"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
                  </button>
                </div>
              </div>
              <button className="unique-modal-change-password-button" onClick={handlePasswordChange}>Change Password</button>
            </>
          )}
          {passwordChanged && (
            <div className="unique-modal-success-content">
              <img src={successImage} alt="Success" className="unique-modal-success-image" />
              <h2>Successful</h2>
              <p>You've successfully changed your password</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModalUnique;
