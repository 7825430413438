import React from 'react';

const FAQs = () => {
  return (
    <section className="faq-section py-20">
      <div className="faq-container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="faq-title text-3xl font-bold mb-8">Frequently Asked Questions</h2>
        <div className="faq-list space-y-4">
          <div className="faq-card bg-purple-500 text-white shadow rounded-lg">
            <div className="faq-question">How do I purchase a voucher or gift card?</div>
            <div className="faq-answer">
            Ah, buying a gift card is easier than finding suya on a Friday night! Just select the voucher or gift card you want, personalize it, and complete the secure payment process. Your voucher or gift card will be delivered instantly via email. No wahala!            </div>
          </div>
          <div className="faq-card bg-yellow-500 text-white shadow rounded-lg">
            <div className="faq-question" style={{color: 'black'}}>What payment methods do you accept?</div>
            <div className="faq-answer">
            We accept all major debit cards, bank transfers and even those Nigerian bank apps that send you 10 OTPs for one transaction. We’ve got you covered!

</div>
          </div>
          <div className="faq-card bg-purple-500 text-white shadow rounded-lg">
            <div className="faq-question">How quickly will I receive my gift card after purchase?</div>
            <div className="faq-answer">
            Faster than you can say “chai!” Our digital delivery ensures you receive your gift card instantly via email. No delays, no stories!            </div>
          </div>
          <div className="faq-card bg-black text-white shadow rounded-lg">
            <div className="faq-question">Is it safe to buy gift cards online from your site?</div>
            <div className="faq-answer">
            Absolutely! Our platform is as secure as your grandmother’s hidden stash of chin chin. We use top-notch security measures to ensure your information stays private and secure.            </div>
          </div>
          <div className="faq-card bg-orange-500 text-white shadow rounded-lg">
            <div className="faq-question">Can I return or exchange my gift card?</div>
            <div className="faq-answer">
            Well, you can’t return a plate of jollof after eating it, right? Same with our gift cards. Once purchased, they are non-refundable. But don’t worry, they never expire—use them anytime!            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQs;
