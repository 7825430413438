import React from 'react';

const CTA = () => {
  return (
    <section className="cta-section">
        <div className="gradient-blur"></div>
      <div className="cta-container">
        <h2 className="cta-title">Join Us and Save!</h2>
        <p className="cta-subtitle">Sign up now and get 10% off your first purchase!</p>
        <div className="cta-button-container">
        <button className="cta-button">Sign Up Now</button>
      </div>
      </div>

    </section>
  );
};

export default CTA;
