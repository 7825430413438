import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ConfirmOrderModal from './ConfirmOrderModal';
import SuccessModal from './SuccessModal';
import logo from '../assets/LogoPurple.png';
import homeIcon from '../assets/homeIconInactive.png';
import giftcardIcon from '../assets/giftcardIconActive.png';
import transactionIcon from '../assets/transactionIconInactive.png';
import './GiftCardPurchase.css';

const GiftCardPurchase = () => {
  const { productId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();    
  const [user, setUser] = useState(location.state?.user || null);
  const [giftCard, setGiftCard] = useState(null);
  const [denominations, setDenominations] = useState([]);
  const [amount, setAmount] = useState('');
  const [priceInNaira, setPriceInNaira] = useState(0);
  const [isForSomeoneElse, setIsForSomeoneElse] = useState(false);
  const [recipientDetails, setRecipientDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });
  const [balance, setBalance] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [rateLimitError, setRateLimitError] = useState(false); // <-- Add this line

  useEffect(() => {
    const fetchGiftCard = async () => {
      try {
        const response = await axios.get(`/api/giftcards/${productId}`);
        const giftCardData = response.data;
        const reloadlyResponse = await axios.get(`/api/reloadly/products/${giftCardData.product_id}`);
        const reloadlyProductData = reloadlyResponse.data;

        if (typeof giftCardData.country === 'string') {
          giftCardData.country = JSON.parse(giftCardData.country);
        }

        setGiftCard(giftCardData);
        if (reloadlyProductData.denominationType === 'RANGE') {
          setDenominations(generateRangeDenominations(reloadlyProductData.minRecipientDenomination, reloadlyProductData.maxRecipientDenomination));
        } else {
          setDenominations(reloadlyProductData.fixedRecipientDenominations);
        }
      } catch (error) {
        console.error('Error fetching gift card:', error);
      }
    };

    fetchGiftCard();
  }, [productId]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user) {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            throw new Error('No token found');
          }
          const response = await axios.get('/api/user', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setUser(response.data);
          setBalance(response.data.availablebalance);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setBalance(user.availablebalance);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    if (giftCard) {
      const exchangeRate = getExchangeRate(giftCard.country.isoName);
      const selectedAmount = parseFloat(amount);
      const calculatedPrice = selectedAmount ? selectedAmount * exchangeRate : 0;
      setPriceInNaira(calculatedPrice.toFixed(2));
    }
  }, [amount, giftCard]);

  useEffect(() => {
    const ws = new WebSocket('ws://localhost:3001'); // Adjust the WebSocket URL as needed
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'BALANCE_UPDATE' && data.userId === user?.id) {
        setBalance(data.newBalance);
      }
    };

    return () => {
      ws.close();
    };
  }, [user]);

  const exchangeRates = {
    US: 1650,
    CA: 1270,
    GB: 2120,
    DE: 1810,
  };

  const currencySymbols = {
    US: '$',
    CA: '$',
    GB: '£',
    DE: '€',
  };

  const getExchangeRate = (countryIsoName) => {
    return exchangeRates[countryIsoName] || 1650;
  };

  const getCurrencySymbol = (countryIsoName) => {
    return currencySymbols[countryIsoName] || '$';
  };

  const generateRangeDenominations = (min, max) => {
    const increments = [10, 20, 25, 50, 75, 100];
    const result = [];
    for (let i = min; i <= max; i++) {
      if (increments.includes(i) || i % 25 === 0) {
        result.push(i);
      }
    }
    return result;
  };

  const handleAmountClick = (amt) => {
    setAmount(amt);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecipientDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handlePayNow = () => {
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmOrder = async (orderData) => {
    console.log('handleConfirmOrder', orderData);

    try {
      const response = await axios.post('/api/orders', orderData);
      console.log('Order response:', response.data);

      if (response.data.transaction) {
        const transaction = response.data.transaction;

        // Add product details to the transaction
        transaction.product = {
          unitPrice: orderData.amount,
          productName: giftCard.product_name,
        };

        // Add the amount in Naira to the transaction
        transaction.amountInNaira = orderData.priceInNaira;

        console.log('Complete transaction object:', transaction);

        setTransaction(transaction);
        setShowSuccessModal(true);
        handleCloseConfirmModal();
        setRateLimitError(false); // Reset rate limit error if the order is successful
      } else {
        alert('Transaction failed. Please try again.');
      }
    } catch (error) {
      if (error.response && error.response.status === 429) {
        setRateLimitError(true); // Show rate limit error message
      } else {
        console.error('Error processing order:', error);
        alert(`Error processing order: ${error.message}`);
      }
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate('/dashboard', { state: { user } });
  };

  const isPayNowDisabled = () => {
    const selectedAmount = parseFloat(amount);
    const isValidAmount = selectedAmount && selectedAmount >= 1 && selectedAmount <= 200;
    const hasSufficientBalance = balance !== null && parseFloat(priceInNaira) <= parseFloat(balance);

    return !(isValidAmount && hasSufficientBalance && (!isForSomeoneElse || (recipientDetails.firstName && recipientDetails.lastName && recipientDetails.email)));
  };

  if (!giftCard || !user) {
    return <p>Loading...</p>;
  }

  const initials = user ? `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`.toUpperCase() : '';

  const handleNavigate = (path) => {
    navigate(path, { state: { user } });
  };

  const currencySymbol = getCurrencySymbol(giftCard.country.isoName);

  return (
    <div className="giftcard-purchase-container">
      <header className="giftcardpurchase-header">
        <img src={logo} alt="Sparkpay Logo" className="giftcardpurchase-logo" />
        <nav className="giftcardpurchase-nav">
          <div onClick={() => handleNavigate('/dashboard')}>
            <img src={homeIcon} alt="Home" /> Home
          </div>
          <div onClick={() => handleNavigate('/MainGiftCard')}>
            <img src={giftcardIcon} alt="Buy Giftcards" /> Buy Giftcards
          </div>
          <div onClick={() => handleNavigate('/transactions')}>
            <img src={transactionIcon} alt="Transaction History" /> Transaction History
          </div>
        </nav>
        <div className="giftcardpurchase-profile">
          <div className="giftcard-profile-icon">{initials}</div>
        </div>
      </header>

      <div className="giftcard-purchase-main-content">
        <div className="giftcard-image-container">
          <h2 className="giftcard-title">Buy Gift Cards</h2>
          <div className="giftcard-image">
            <img src={giftCard.logo_urls[0]} alt={giftCard.product_name} />
          </div>
        </div>
        <div className={`giftcard-details ${isForSomeoneElse ? 'extra-margin' : ''}`}>
          <h2>{giftCard.product_name}</h2>
          <p>Emailed in Approximately 5 Mins or Less</p>
          <div className="giftcard-amount">
            <div className="balance-label">Available Balance: NGN {balance !== null && balance !== undefined ? parseFloat(balance).toLocaleString('en-NG', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'Loading...'}</div>
            <label>Choose an amount</label>
            <div className="amount-options">
              {denominations.map((amt) => (
                <button
                  key={amt}
                  className={`amount-button ${amount === amt ? 'selected' : ''}`}
                  onClick={() => handleAmountClick(amt)}
                >
                  {currencySymbol}{amt}
                </button>
              ))}
            </div>
          </div>
          <div className="giftcard-price">
            <label>Price in Naira</label>
            <div className="price-display">NGN {parseFloat(priceInNaira).toLocaleString()}</div>
          </div>
          <div className="recipient-options">
            <label>For</label>
            <div className="recipient-radio">
              <input
                type="radio"
                id="forMyself"
                name="recipient"
                checked={!isForSomeoneElse}
                onChange={() => setIsForSomeoneElse(false)}
              />
              <label htmlFor="forMyself">For Myself</label>
              <input
                type="radio"
                id="forSomeoneElse"
                name="recipient"
                checked={isForSomeoneElse}
                onChange={() => setIsForSomeoneElse(true)}
              />
              <label htmlFor="forSomeoneElse">For Someone Else</label>
            </div>
          </div>
          {isForSomeoneElse && (
            <div className="recipient-details">
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                value={recipientDetails.firstName}
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={recipientDetails.lastName}
                onChange={handleInputChange}
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={recipientDetails.email}
                onChange={handleInputChange}
              />
              <textarea
                name="message"
                placeholder="Add an optional message"
                value={recipientDetails.message}
                onChange={handleInputChange}
                maxLength="250"
              />
              <p>Enter a personalized message for the recipient.</p>
            </div>
          )}
          <div className="button-container">
            <button
              className={`pay-now-button ${isPayNowDisabled() ? 'disabled' : ''}`}
              onClick={handlePayNow}
              disabled={isPayNowDisabled()}
            >
              Pay Now
            </button>
          </div>
          {rateLimitError && (
            <div className="error-message">
              Ouch... Slow down, you have a one request in progress.
            </div>
          )}
        </div>
      </div>

      <footer className="GiftcardPurchase-footer">
        <p>© Copyright 4, All Rights Reserved. Bitbloom Technologies.</p>
      </footer>

      <ConfirmOrderModal
        show={showConfirmModal}
        onClose={handleCloseConfirmModal}
        giftCard={giftCard}
        amountInDollars={amount}
        priceInNaira={priceInNaira}
        recipientDetails={recipientDetails}
        balance={balance}
        currencySymbol={currencySymbol}
        user={user}
        onConfirmOrder={handleConfirmOrder}
      />

      {showSuccessModal && (
        <SuccessModal
          transaction={transaction}
          onClose={handleCloseSuccessModal}
        />
      )}
    </div>
  );
};

export default GiftCardPurchase;
