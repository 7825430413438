import React from 'react';
import giftCards from '../assets/gift-cards.png'; // Ensure you replace this with the correct path to your image

const Hero = () => {
  return (
    <section className="text-white py-20 relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center justify-between">
        <div className="text-center lg:text-left">
          <h1 className="gift-heading">Gift Happiness with Vouchers and Gift Cards!</h1>
          <p className="mt-4 gift-description">Find the perfect voucher or gift card for every occasion. Easily purchase and send them online with instant delivery.</p>
          <button className="custom-button mt-8">
            <span className="button-text">Shop Now</span>
          </button>
        </div>
        <div className="relative flex items-center justify-end lg:static">
          <img src={giftCards} alt="Gift Cards" className="gift-cards-img" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
