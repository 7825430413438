import axios from 'axios';

const setupAxiosInterceptors = () => {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response) {
        const status = error.response.status;
        const requestUrl = error.response.config.url;

        if (status === 401) {
          // Handle 401 Unauthorized
          localStorage.removeItem('token');
          localStorage.removeItem('isAuthenticated');
          window.location.href = '/signin';
        } else if (status === 400) {
          // Handle 400 Bad Request for specific cases
          if (requestUrl.includes('/verify-email')) {
            // Do not redirect, allow the error to be handled by the frontend component
            return Promise.reject(error);
          }
          
          // Otherwise, handle it as you did previously
          // E.g., show an error message, or handle it in some other way
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  );
};

export default setupAxiosInterceptors;
