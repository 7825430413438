import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/logo.png';
import backgroundImage from '../assets/background.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import './loading.css';
import axiosInstance from '../axiosSetup'; // Adjust the path as needed


const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [verifiedMessage, setVerifiedMessage] = useState('');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('verified') === 'true') {
      setVerifiedMessage('Account verified');
      setTimeout(() => setVerifiedMessage(''), 2000);
    }
  }, [location]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };
const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);
  try {
    const { email, password } = formData;
    console.log('Submitting sign-in form with:', formData);
    const signInResponse = await axios.post('/api/signin', { email, password }, { withCredentials: true });
    console.log('User signed in successfully:', signInResponse.data);

    const userResponse = await axios.get('/api/user', { withCredentials: true });
    console.log('Fetched user data:', userResponse.data);

    // Set isAuthenticated to true in localStorage
    localStorage.setItem('isAuthenticated', 'true');

    const giftCardsResponse = await axios.get('/api/giftcards', { withCredentials: true });
    console.log('Fetched gift cards data:', giftCardsResponse.data);

    setTimeout(() => {
      navigate('/dashboard', { state: { user: userResponse.data, giftCards: giftCardsResponse.data } });
    }, 300);
  } catch (error) {
    console.error('Error during sign-in:', error);
    if (error.response) {
      console.log('Error response from server:', error.response.data);
      if (error.response.status === 400) {
        setErrorMessage('Email not verified, please check your email address for the verification email.');
      } else if (error.response.status === 403) {
        setErrorMessage('Account Deactivated');
      } else if (error.response.status === 401) {
        setErrorMessage('Invalid email or password');
      } else {
        setErrorMessage('Sign-in failed. Please try again.');
      }
    } else {
      setErrorMessage('Sign-in failed. Please try again.');
    }
    setIsLoading(false);
  }
};


  return (
    <div className="signin-container-custom">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loader">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="signin-left-custom" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <img src={logo} alt="Sparkpay Logo" className="signin-logo" />
        <p className="logo-description">Gift Smiles, One Voucher at a Time</p>
      </div>
      <div className="signin-right-custom">
        <h1 className="signin-title-custom">Sign In</h1>
        {verifiedMessage && <p className="verified-message">{verifiedMessage}</p>}
        <form className="signin-form-custom" onSubmit={handleSubmit}>
          <div className="form-group-custom">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter email address"
              maxLength="40"
              onChange={handleChange}
              required
              autoComplete="email"
            />
          </div>
          <div className="form-group-custom">
            <label htmlFor="password">Password</label>
            <div className="password-container">
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                placeholder="Enter password"
                maxLength="30"
                onChange={handleChange}
                required
                autoComplete="current-password"
              />
              <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
              </span>
            </div>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <p className="forgot-password-link">
            <a href="/forgot-password">Forgot Password?</a>
          </p>
          <button type="submit" className="signin-button-custom">Sign In</button>
          <p className="signin-link-custom">
            Don't have an account? <a href="/signup"><span>Sign Up</span></a> instead
          </p>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
