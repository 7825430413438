import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Profile.css';
import logo from '../assets/LogoPurple.png';
import homeIcon from '../assets/homeIconInactive.png';
import giftcardIcon from '../assets/giftcardIconInactive.png';
import transactionIcon from '../assets/transactionIconActive.png';
import ChangePasswordModal from './ChangePasswordModal';
import DeleteAccountModal from './DeleteAccountModal';
import axios from 'axios';
import badge from '../assets/badge.png';
import unverifiedBadge from '../assets/UnverifiedBadge.png';

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(location.state?.user);
  const [profileImage, setProfileImage] = useState(null);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        console.log('Fetching user data...');
        const response = await axios.get('/api/user', {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
        });
        console.log('User data received:', response.data);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    if (!user) {
      fetchUserData();
    }
  }, [user]);

  const handleNavigate = (path) => {
    navigate(path, { state: { user } });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please select a valid image file.');
    }
  };

  const handleChangeProfileImageClick = () => {
    document.querySelector('.Profile-avatar-input').click();
  };

  const handleCopyAccountDetails = () => {
    navigator.clipboard.writeText(user.account_number)
      .then(() => {
        alert('Account number copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleChangePasswordClick = () => {
    setShowChangePasswordModal(true);
  };

  const closeChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };

  const handleDeleteAccountClick = () => {
    setShowDeleteAccountModal(true);
  };

  const closeDeleteAccountModal = () => {
    setShowDeleteAccountModal(false);
  };

  const handleDeleteAccount = async () => {
    try {
      const response = await axios.post('/api/delete-account', {}, {
        headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
      });
      if (response.data.success) {
        setIsDeleted(true);
        setTimeout(() => {
          navigate('/signin');
        }, 2000);
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      alert('Error deleting account. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post('/api/signout', {}, { withCredentials: true });
  
      // Notify other tabs about the logout
      localStorage.setItem('logout', Date.now());
  
      // Clear authentication status from localStorage
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('token');
  
      // Redirect to the login page
      navigate('/signin');
  
      // Prevent back button access after logout
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = function() {
        window.history.pushState(null, '', window.location.href);
      };
    } catch (error) {
      console.error('Error during signout:', error);
    }
  };
  

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="Profile-container">
      <header className="Profile-header">
        <img src={logo} alt="Sparkpay Logo" className="Profile-logo" />
        <nav className="Profile-nav">
          <div onClick={() => handleNavigate('/dashboard')}>
            <img src={homeIcon} alt="Home" /> Home
          </div>
          <div onClick={() => handleNavigate('/MainGiftCard')}>
            <img src={giftcardIcon} alt="Buy Giftcards" /> Buy Giftcards
          </div>
          <div onClick={() => handleNavigate('/transactions')}>
            <img src={transactionIcon} alt="Transaction History" /> Transaction History
          </div>
        </nav>
        <div className="Profile-profile">
          <div className="Profile-profile-icon" onClick={() => handleNavigate('/profile')}>
            {user?.first_name?.charAt(0) ?? ''}{user?.last_name?.charAt(0) ?? ''}
          </div>
        </div>
      </header>

      <div className="Profile-content">
        <div className="Profile-wrapper">
          <h1>Profile</h1>

          <div className="Profile-overview">
            <div className="Profile-avatar" onClick={handleChangeProfileImageClick}>
              {profileImage ? (
                <img src={profileImage} alt="Profile" className="Profile-avatar-image" />
              ) : (
                <div className="Profile-avatar-placeholder"></div>
              )}
              <input type="file" accept="image/*" onChange={handleImageUpload} className="Profile-avatar-input" />
            </div>
            <div className="Profile-overview-details">
              <h2>
                {user?.first_name} {user?.last_name} 
                <span className="Profile-verified">
                  {user?.kycstatus ? (
                    <>
                      Verified
                      <img src={badge} alt="Verified Badge" className="Profile-verified-badge" />
                    </>
                  ) : (
                    <>
                      Unverified
                      <img src={unverifiedBadge} alt="Unverified Badge" className="Profile-verified-badge" />
                    </>
                  )}
                </span>
              </h2>
              <p>{user?.email}</p>
              <button className="Profile-button" onClick={handleChangeProfileImageClick}>Change Profile Image</button>
            </div>
            <button className="Profile-change-password-button" onClick={handleChangePasswordClick}>Change Password</button>
          </div>

          <div className="Profile-section">
            <h3>Personal Information</h3>
            <div className="Profile-row">
              <div className="Profile-column">
                <p><strong>First name</strong><span>{user?.first_name}</span></p>
              </div>
              <div className="Profile-column">
                <p><strong>Last name</strong><span>{user?.last_name}</span></p>
              </div>
            </div>
            <div className="Profile-row">
              <div className="Profile-column">
                <p><strong>Email address</strong><span>{user?.email}</span></p>
              </div>
              <div className="Profile-column">
                <p><strong>Phone</strong><span>{user?.phone_number}</span></p>
              </div>
            </div>
          </div>

          <div className="Profile-section">
            <h3>Account Details</h3>
            <p><strong>Bank:</strong> {user?.bank_name}</p>
            <p><strong>Account number:</strong> {user?.account_number}</p>
            <p><strong>Account name:</strong> {user?.account_name}</p>
            {user?.account_number && (
              <div className="Profile-copy-button-wrapper">
                <button className="Profile-copy-button" onClick={handleCopyAccountDetails}>Copy Account Details</button>
              </div>
            )}
          </div>

          <div className="Profile-logout-button-wrapper">
            <button className="Profile-logout-button" onClick={handleLogout}>Logout</button>
          </div>

          <button className="Profile-delete-button" onClick={handleDeleteAccountClick}>Delete Account</button>
        </div>
      </div>

      <footer className="Profile-footer">
        <p>© Copyright 2024, All Rights Reserved. Bitbloom Technologies.</p>
      </footer>

      <ChangePasswordModal show={showChangePasswordModal} onClose={closeChangePasswordModal} />
      <DeleteAccountModal
        show={showDeleteAccountModal}
        onClose={closeDeleteAccountModal}
        onDelete={handleDeleteAccount}
        isDeleted={isDeleted}
      />
    </div>
  );
};

export default Profile;
