import React, { useEffect } from 'react';
import './DeleteAccountModal.css';

const DeleteAccountModal = ({ show, onClose, onDelete, isDeleted }) => {
  useEffect(() => {
    if (isDeleted) {
      const timeout = setTimeout(() => {
        onClose();
      }, 2000); // Adjust the delay as needed
      return () => clearTimeout(timeout);
    }
  }, [isDeleted, onClose]);

  if (!show) return null;

  return (
    <>
      <div className="delete-account-modal-overlay" onClick={onClose}></div>
      <div className="delete-account-modal">
        <div className="delete-account-modal-content">
          <span className="delete-account-close-button" onClick={onClose}>&times;</span>
          {isDeleted ? (
            <h2 className="delete-account-title">Account Deactivated & Deleted</h2>
          ) : (
            <>
              <h2 className="delete-account-title">Do you really want to delete your account?</h2>
              <div>
                <button className="delete-account-button delete-account-button-cancel" onClick={onClose}>Cancel</button>
                <button className="delete-account-button delete-account-button-delete" onClick={onDelete}>Delete Account</button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DeleteAccountModal;
