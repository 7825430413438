import React, { useState } from 'react';
import './NairaInput.css'; // Import the CSS file for styling

const NairaInput = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (e) => {
    let value = e.target.value.replace(/[^0-9]/g, ''); // Remove all non-numeric characters
    if (value.length > 8) {
      value = value.slice(0, 8); // Limit to 8 characters
    }
    let numericValue = parseInt(value) || 0; // Convert to number or default to 0

    // Apply min and max limits
    if (numericValue > 10000000) {
      numericValue = 10000000;
    }

    // Format as currency
    const formattedValue = numericValue ? `₦${numericValue.toLocaleString()}` : '';
    setInputValue(formattedValue);
    onChange(formattedValue);
  };

  return (
    <div className="NairaInput-container">
      <input
        type="text"
        className="NairaInput-input"
        value={inputValue}
        onChange={handleChange}
        placeholder="0.00" // Updated placeholder to remove extra Naira symbol
      />
    </div>
  );
};

export default NairaInput;
