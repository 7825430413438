import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';

const Header = () => {
  return (
    <header className="py-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Sparkpay Logo" className="logo" />
        </div>
        <div className="header-buttons-container">
        <Link to="./signin">
          <button className="header-button header-button-signin">Sign In</button>
          </Link>
          <Link to="./signup">
            <button className="header-button header-button-getstarted">Get Started</button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
