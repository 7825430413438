import React, { useState } from 'react';
import CustomerReviewBackground1 from '../assets/CustomerReview1.png'; // Adjust the path accordingly
import CustomerReviewBackground2 from '../assets/CustomerReview2.png'; // Adjust the path accordingly
import NextButton from '../assets/NextButton.png'; // Adjust the path accordingly
import BackButton from '../assets/BackButton.png'; // Adjust the path accordingly

const reviews = [
  {
    text: "Great selection of vouchers and gift cards, and very easy to use. Perfect for last-minute gifts.",
    background: CustomerReviewBackground1
  },
  {
    text: "Amazing experience! The platform is user-friendly and the customer service is top-notch. Highly recommend!",
    background: CustomerReviewBackground2
  }
];

const CustomerReviews = () => {
  const [currentReview, setCurrentReview] = useState(0);

  const handleNext = () => {
    setCurrentReview((prev) => (prev + 1) % reviews.length);
  };

  const handlePrev = () => {
    setCurrentReview((prev) => (prev - 1 + reviews.length) % reviews.length);
  };

  return (
    <section className="customer-reviews-section py-20 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="customer-reviews-title text-3xl font-bold mb-8">Customer Reviews</h2>
        <div className="customer-review" style={{ backgroundImage: `url(${reviews[currentReview].background})` }}>
          <p className="customer-review-text">{reviews[currentReview].text}</p>
        </div>
        <div className="review-navigation mt-4">
          <button className="prev-button mr-4" onClick={handlePrev}>
            <img src={BackButton} alt="Previous Review" />
          </button>
          <button className="next-button" onClick={handleNext}>
            <img src={NextButton} alt="Next Review" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default CustomerReviews;
