import React from 'react';
import './SuccessModal.css';
import SuccessImage from '../assets/SuccessfullyBought.png';

const SuccessModal = ({ transaction, onClose }) => {
  if (!transaction || !transaction.product) {
    return null;
  }

  return (
    <div className="success-modal-overlay">
      <div className="success-modal">
        <img src={SuccessImage} alt="Success" className="success-image" />
        <h2 className="success-title">Successfully bought</h2>
        <h3 className="success-amount">
          ${transaction.product.unitPrice} {transaction.product.productName}
        </h3>
        <p className="success-bought-for">
          Bought for <span className="amount-naira">{transaction.amountInNaira} NGN</span>
        </p>
        <div className="success-message">
          <p>Thank you for your purchase!</p>
          <p>
            Your gift card has been successfully processed. Please check your
            email address for the details and instructions on how to use your
            gift card. If you do not see the email in your inbox, be sure to
            check your spam or junk folder.
          </p>
          <p>
            If you have any questions or need further assistance, please contact
            our support team at account@gyftcard.ng.
          </p>
          <p>Enjoy your gift card!</p>
        </div>
        <button onClick={onClose} className="success-back-button">
          Back to Dashboard
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
