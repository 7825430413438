import React from 'react';
import TapIcon from '../assets/TapIcon.png';
import GiftIcon from '../assets/GiftIcon.png';
import PaymentIcon from '../assets/PaymentIcon.png';
import DeliveryIcon from '../assets/DeliveryIcon.png';

const HowItWorks = () => {
  return (
    <section className="how-it-works-section">
      <div className="how-it-works-container">
        <div className="how-it-works-title-container">
          <h2 className="how-it-works-title">How It Works</h2>
      

        </div>
        <div className="how-it-works-steps">
          <div className="how-it-works-step">
            <div className="step-number">Step 1</div>
            <img className="step-icon" src={TapIcon} alt="Step 1 Icon" />
            <h3 className="step-title">Choose Your Voucher or Gift Card</h3>
            <p className="step-description">Browse our wide range of categories to find the perfect voucher.</p>
          </div>
          <div className="how-it-works-step">
            <div className="step-number">Step 2</div>
            <img className="step-icon" src={GiftIcon} alt="Step 2 Icon" />
            <h3 className="step-title">Personalize Your Gift</h3>
            <p className="step-description">Browse our wide range of categories to find the perfect voucher.</p>
          </div>
          <div className="how-it-works-step">
            <div className="step-number">Step 3</div>
            <img className="step-icon" src={PaymentIcon} alt="Step 3 Icon" />
            <h3 className="step-title">Secure Payment</h3>
            <p className="step-description">Browse our wide range of categories to find the perfect voucher.</p>
          </div>
          <div className="how-it-works-step">
            <div className="step-number">Step 4</div>
            <img className="step-icon" src={DeliveryIcon} alt="Step 4 Icon" />
            <h3 className="step-title">Instant Delivery</h3>
            <p className="step-description">Browse our wide range of categories to find the perfect voucher.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
