import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import logo from '../assets/LogoPurple.png';
import homeIcon from '../assets/homeIconInactive.png';
import giftcardIcon from '../assets/giftcardIconInactive.png';
import transactionIcon from '../assets/transactionIconActive.png';
import './MainTransaction.css';
import './customDateRangePicker.css';
import './TransactionModal.css'; 
import downloadIcon from '../assets/Downloadicon.svg';

const MainTransaction = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user;
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const limit = 10;
  const [selectedTransaction, setSelectedTransaction] = useState(null); 

  const [state, setState] = useState([
    {
      startDate: new Date('2024-01-01'), 
      endDate: new Date(), 
      key: 'selection'
    }
  ]);

  const fetchTransactions = async (startDate, endDate, newPage = page) => {
    try {
      setLoading(true);
      const offset = (newPage - 1) * limit;
      const response = await axios.get('/api/transactions/all', {
        params: {
          limit,
          page: newPage,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }
      });
      setTransactions(response.data.transactions);
      setTotal(response.data.total);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleApplyDates = () => {
    fetchTransactions(state[0].startDate, state[0].endDate, 1);
    setShowDatePicker(false);
    setPage(1); 
  };

  useEffect(() => {
    if (!user) {
      navigate('/signin');
      return;
    }
    fetchTransactions(state[0].startDate, state[0].endDate);
  }, [user, navigate, state, page]);

  const initials = `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`.toUpperCase();

  const handleNavigate = (path) => {
    navigate(path, { state: { user } });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const totalPages = Math.ceil(total / limit);

  const handleClearDates = () => {
    setState([{ startDate: new Date('2024-01-01'), endDate: new Date(), key: 'selection' }]);
  };

  const formatAmount = (amount) => {
    return parseFloat(amount).toLocaleString('en-NG', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const handleView = (transaction) => {
    setSelectedTransaction(transaction);
  };

  const closeModal = () => {
    setSelectedTransaction(null);
  };

  const copyToClipboard = () => {
    if (selectedTransaction) {
      navigator.clipboard.writeText(selectedTransaction.transaction_ref);
      alert('Transaction reference copied to clipboard!');
    }
  };

  const downloadReceipt = async () => {
    const input = document.querySelector('.transaction-modal-content');
    const canvas = await html2canvas(input, {
      scale: 3, 
      useCORS: true,
      logging: true,
      scrollX: 0,
      scrollY: 0,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
    });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('receipt.pdf');
  };

  const generatePaginationButtons = () => {
    const buttons = [];
    const maxButtons = 5;

    if (totalPages <= maxButtons) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            className={`page-button ${page === i ? 'active' : ''}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }
    } else {
      let startPage, endPage;

      if (page <= Math.ceil(maxButtons / 2)) {
        startPage = 1;
        endPage = maxButtons;
      } else if (page + Math.floor(maxButtons / 2) >= totalPages) {
        startPage = totalPages - maxButtons + 1;
        endPage = totalPages;
      } else {
        startPage = page - Math.floor(maxButtons / 2);
        endPage = page + Math.floor(maxButtons / 2);
      }

      for (let i = startPage; i <= endPage; i++) {
        buttons.push(
          <button
            key={i}
            className={`page-button ${page === i ? 'active' : ''}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      }

      if (startPage > 1) {
        buttons.unshift(
          <button
            key="prev-ellipsis"
            className="page-button ellipsis"
            disabled
          >
            ...
          </button>
        );
        buttons.unshift(
          <button
            key={1}
            className={`page-button ${page === 1 ? 'active' : ''}`}
            onClick={() => handlePageChange(1)}
          >
            1
          </button>
        );
      }

      if (endPage < totalPages) {
        buttons.push(
          <button
            key="next-ellipsis"
            className="page-button ellipsis"
            disabled
          >
            ...
          </button>
        );
        buttons.push(
          <button
            key={totalPages}
            className={`page-button ${page === totalPages ? 'active' : ''}`}
            onClick={() => handlePageChange(totalPages)}
          >
            {totalPages}
          </button>
        );
      }
    }

    return buttons;
  };

  return (
    <div className="MainTransaction-main-transaction-container">
      <header className="MainTransaction-main-transaction-header">
        <img src={logo} alt="Sparkpay Logo" className="MainTransaction-main-transaction-logo" />
        <nav className="MainTransaction-main-transaction-nav">

          
  <div onClick={() => handleNavigate('/dashboard')}>
    <img src={homeIcon} alt="Home" /> Home
  </div>
  <div onClick={() => handleNavigate('/MainGiftCard')}>
    <img src={giftcardIcon} alt="Buy Giftcards" /> Buy Giftcards
  </div>
  <div onClick={() => handleNavigate('/transactions')} className={location.pathname === '/transactions' ? 'active' : ''}>
    <img src={transactionIcon} alt="Transaction History" /> Transaction History
  </div>
</nav>

        <div className="MainTransaction-main-transaction-profile">
          <div className="MainTransaction-profile-icon" onClick={() => handleNavigate('/profile')}>{initials}</div> {/* Update this line */}
        </div>
      </header>

      <div className="MainTransaction-main-transaction-content">
        <div className="MainTransaction-transaction-wrapper">
          <div className="MainTransaction-transaction-header">
            <h2>Transaction History</h2>
            <div className="MainTransaction-sort-filter">
              <div
                className="MainTransaction-date-range-input"
                onClick={() => setShowDatePicker(!showDatePicker)}
              >
                <span>
                  {state[0].startDate.toLocaleDateString('en-GB')} - {state[0].endDate.toLocaleDateString('en-GB')}
                </span>
              </div>
            </div>
          </div>
          <div className="DateRangePicker-wrapper">
            {showDatePicker && (
              <div className="DateRangePicker-overlay">
                <button
                  className="DateRangePicker-close-btn"
                  onClick={() => setShowDatePicker(false)}
                >
                  ✕
                </button>
                <DateRangePicker
                  onChange={item => setState([item.selection])}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                  months={2}
                  direction="horizontal"
                />
                <div className="DateRangePicker-buttons">
                  <button className="clear-btn" onClick={handleClearDates}>Clear</button>
                  <button onClick={handleApplyDates}>Apply</button>
                </div>
              </div>
            )}
          </div>

          {loading ? (
            <p>Loading...</p>
          ) : (
            <table className="MainTransaction-transaction-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Voucher</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {transactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>{new Date(transaction.datetime).toLocaleDateString('en-GB')}</td>
                    <td>{transaction.type === 'credit' ? 'Top-up' : transaction.giftcard}</td>
                    <td className={transaction.type === 'credit' ? 'positive' : 'negative'}>
                      {formatAmount(transaction.amount_naira)} NGN
                    </td>
                    <td>
                      <button className="transaction-view-button" onClick={() => handleView(transaction)}>
                        View <span role="img" aria-label="note">📝</span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <div className="pagination">
            {generatePaginationButtons()}
          </div>
        </div>
      </div>

      {selectedTransaction && (
        <div className="transaction-modal-overlay">
          <div className="transaction-modal">
            <button className="transaction-modal-close-btn" onClick={closeModal}>✕</button>
            <div className="transaction-modal-content">
              <h2>Receipt</h2>
              <p><strong>Payment Method:</strong> {selectedTransaction.payment_method}</p>
              <p><strong>Date & Time:</strong> {new Date(selectedTransaction.datetime).toLocaleString()}</p>
              <p><strong>Gift Card:</strong> {selectedTransaction.giftcard}</p>
              <p><strong>Amount (USD):</strong> ${selectedTransaction.amount_usd}</p>
              <p><strong>Amount (Naira):</strong> ₦{formatAmount(selectedTransaction.amount_naira)}</p>
              <p><strong>Transaction Reference No.:</strong> {selectedTransaction.transaction_ref}</p>
              <p><strong>Status:</strong> <span className={`status ${selectedTransaction.status.toLowerCase()}`}>{selectedTransaction.status}</span></p>
              <button className="transaction-download-receipt-btn" onClick={downloadReceipt}>
                Download Receipt <img src={downloadIcon} alt="Download" />
              </button>
            </div>
          </div>
        </div>
      )}

      <footer className="MainTransaction-main-transaction-footer">
        <p>© Copyright 2024, All Rights Reserved. Bitbloom Technologies.</p>
      </footer>
    </div>
  );
};

export default MainTransaction;
