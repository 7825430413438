import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import setupAxiosInterceptors from './axiosSetup';
import HeaderHeroWrapper from './components/HeaderHeroWrapper';
import HowItWorks from './components/HowItWorks';
import PopularCategories from './components/PopularCategories';
import WhyChooseUs from './components/WhyChooseUs';
import FAQs from './components/FAQs';
import CustomerReviews from './components/CustomerReviews';
import CTA from './components/CTA';
import Footer from './components/Footer';
import SignUp from './components/SignUp';
import SignIn from './components/Signin';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Dashboard from './components/Dashboard';
import MainGiftCard from './components/MainGiftCard';
import GiftCardPurchase from './components/GiftCardPurchase';
import MainTransaction from './components/MainTransaction';
import Profile from './components/Profile';
import VerifyEmail from './components/VerifyEmail';

// Initialize Axios interceptors
setupAxiosInterceptors();

// Function to get authentication status
const isAuthenticated = () => {
  return localStorage.getItem('isAuthenticated') === 'true';
};

// Protected Route component
const ProtectedRoute = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to="/signin" />;
};

// Public Route component
const PublicRoute = ({ children }) => {
  return !isAuthenticated() ? children : <Navigate to="/dashboard" />;
};

// Component to handle logout event listener
const LogoutListener = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogoutEvent = (e) => {
      if (e.key === 'logout') {
        // Clear storage and redirect to login page on logout event
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('token');
        navigate('/signin');
      }
    };

    window.addEventListener('storage', handleLogoutEvent);

    return () => {
      window.removeEventListener('storage', handleLogoutEvent);
    };
  }, [navigate]);

  return null;
};

function App() {
  return (
    <Router>
      <LogoutListener />
      <Routes>
        {/* Redirect to dashboard if the user is authenticated, otherwise render the home page */}
        <Route 
          path="/" 
          element={isAuthenticated() 
            ? <Navigate to="/dashboard" /> 
            : <div>
                <HeaderHeroWrapper />
                <HowItWorks />
                <PopularCategories />
                <WhyChooseUs />
                <FAQs />
                <CustomerReviews />
                <CTA />
                <Footer />
              </div>
          } 
        />

        {/* Public routes */}
        <Route path="/signup" element={<PublicRoute><SignUp /></PublicRoute>} />
        <Route path="/signin" element={<PublicRoute><SignIn /></PublicRoute>} />
        <Route path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
        <Route path="/reset-password" element={<PublicRoute><ResetPassword /></PublicRoute>} />
        <Route path="/verify-email" element={<PublicRoute><VerifyEmail /></PublicRoute>} /> 

        {/* Protected routes */}
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/MainGiftCard" element={<ProtectedRoute><MainGiftCard /></ProtectedRoute>} />
        <Route path="/giftcard/:productId" element={<ProtectedRoute><GiftCardPurchase /></ProtectedRoute>} />
        <Route path="/transactions" element={<ProtectedRoute><MainTransaction /></ProtectedRoute>} />
        <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
