import React from 'react';
import Header from './Header';
import Hero from './Hero';

const HeaderHeroWrapper = () => {
  return (
    <div className="header-hero-bg">
      <Header />
      <Hero />
    </div>
  );
};

export default HeaderHeroWrapper;
